import '@popperjs/core'; // Import Popper.js, which Bootstrap needs for dropdowns, popovers, and tooltips
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import * as hovercss from '/node_modules/hover.css/css/hover.css'; //CSS for hover effects

import 'animate.css'; // Optional CSS for animations

// Axios Setup
import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Keep other imports as they are
import AOS from 'aos';
import 'aos/dist/aos.css';

// Initialize AOS with optimized settings
document.addEventListener('DOMContentLoaded', () => {
    AOS.init({
        duration: 800,
        offset: 150, // Increased trigger distance
        once: true, // Prevent re-animation
        disable: window.innerWidth < 1024, // Disable on smaller screens
        startEvent: 'DOMContentLoaded' // Earlier initialization
      });

    // Refresh AOS after dynamic content loads (if using AJAX)
    window.addEventListener('ajax:complete', () => AOS.refresh());
});

